import { graphql, Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import ContactForm from "../components/contactForm/form";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import Seo from "../components/seo";
import SlickArrowLeft from "../components/SlickArrowLeft";
import SlickArrowRight from "../components/slickArrowRight";
import Testimonials from "../components/testimonials/testimonials";
import Title from "../components/title/title";
import Top from "../components/top/top";
import iconAdv1 from "../images/CustomerStoriesPops/icon-adv-1.png";
import iconAdv2 from "../images/CustomerStoriesPops/icon-adv-2.png";
import iconAdv3 from "../images/CustomerStoriesPops/icon-adv-3.png";
import gradientBg from "../images/OrdersConsolidation/gradient-pink-bg.png";
import YoutubeVideo from './../components/youtubeVideo/youtubeVideo';

// markup
const CustomerStoriesPopsPage = ({ location, data }) => {

  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const topProps = {
    layout: 2,
    title: <>At Orders.co we treat our customers as our partners.</>,
    hideLine: true,
    subtitle: ``,
    scrollToForm: scrollToForm,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="customers"
        src="../images/CustomerStoriesPops/customers.png"
        className="mw-100"
      />
    ),
  };

  const testimonials = [
    {
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          src="../images/CustomerStoriesPops/Marthin.png"
          alt="Marthin Ken"
          className="mw-100 w-100"
        />
      ),
      text: `The team is just amazing, whenever we have a question just one call and they fix the issue.
                I’ve been very happy since I started using orders.co, because my job has become a lot easier.`,
      author: "Marthin Ken",
      position: "Owner of Pops Creamery",
    },
  ];

  const testimonialsProps = {
    layout: 5,
    items: testimonials,
    title: <span className="text-left d-block">Pops Artisanal Creamery</span>,
    tag: "",
  };

  const features = [
    {
      bg: "#DEFFEE",
      icon: iconAdv1,
      title: "Personalized Website",
      description: `Create your very own ordering website in no time with just your menu.`,
    },
    {
      bg: "#F1F7FF",
      icon: iconAdv2,
      title: "Direct Ordering",
      description: `Cut out the middlemen and give your customers an easy way to order directly from you without the commission!`,
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: "Reliable Delivery",
      description: `Offer delivery on all website orders with in-house staff or through our delivery partners.`,
    },
  ];

  const imageTextProps1 = {
    layout: "equal-big",
    title: "Customers Situation",
    titleStyle: "",
    imagePosition: "left",
    description: (
      <p style={{ fontSize: "22px" }}>
        Marthin, owner of Pop’s Artisanal Creamery, started his shop in 2015.
        His vision was to create unique small batch flavors and offer his
        customers a unique ice cream experience. With everything from exotic
        latin fruits to an array of vegan options, within a few years Marthin
        had expanded to 3 locations. But, like many small businesses, Marthin
        needed to expand his revenue channels if he wanted to continue growing.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="revenue"
        src="../images/CustomerStoriesPops/tablet-delivery.png"
      />
    ),
  };

  const imageTextProps2 = {
    layout: "equal-big",
    title: "The Challenge",
    titleStyle: "",
    imagePosition: "right",
    description: (
      <p style={{ fontSize: "22px" }}>
        The nature of selling small batch artisan flavors is limited quantity.
        Within months of offering his unique flavors on delivery apps, Marthin
        noticed his stores kept receiving orders for soldout flavors. While he
        tried to get his staff to properly update the app menus, it proved
        difficult to train them on 4 different systems. This trend lead to a
        noticeable loss in revenue that had to be stopped.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="challenge"
        src="../images/CustomerStoriesPops/challenge.png"
      />
    ),
  };

  const imageTextProps3 = {
    layout: "equal-big",
    title: "Orders.co Solution",
    titleStyle: "",
    imagePosition: "left",
    description: (
      <p style={{ fontSize: "22px" }}>
        Marthin signed up for a Premium Package and now has one device to
        service all his orders. His staff can quickly update specific flavor
        availability with a few clicks on their Master Menu and have all
        connected menus updated. A custom ordering website was crafted from
        Marthin’s menu and now he offers Loyalty Rewards for repeat business.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="orders.co solution"
        src="../images/CustomerStoriesPops/Orders.co-Solution.png"
      />
    ),
  };

  const imageTextProps4 = {
    layout: "equal-big",
    title: "The Outcome",
    titleStyle: "",
    imagePosition: "right",
    description: (
      <p style={{ fontSize: "22px" }}>
        With the implementation of Orders.co, Marthin has eliminated any revenue
        loss from soldout flavors. His staff only spend ¼ of the time managing
        his delivery apps and he’s seen revenue increase across all 3 locations.
        Free from the stress of online ordering, Marthin is able to focus on
        what he loves. Crafting new and exciting recipes to delight his loyal
        customers.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="outcome"
        src="../images/CustomerStoriesPops/outcome.png"
      />
    ),
  };

  const features2 = [
    {
      bg: "#ff6b0033",
      number: 1,
      title: "",
      description: `Over the last few years, his vision has grown with the addition of two new locations.
                But despite his success, there was one problem that has been a consistent thorn in his side.
                The use of delivery apps has been a welcomed source of additional revenue and provides new
                customers an easy avenue to try Marthin’s Ice Cream. Managing the menus for those apps has
                proven difficult because of his business model.`,
    },
    {
      bg: "#ff6b0033",
      number: 2,
      title: "",
      description: `Offering small batch artisanal flavors, while enticing for customers, sellout
                quickly when spread over 3 locations. Orders were being missed or rejected due to inventory
                shortages. Staff would forget to update one of the delivery apps or all of them entirely.
                Causing a loss of revenue and dissatisfied customers.`,
    },
    {
      bg: "#ff6b0033",
      number: 3,
      title: "",
      description: `Soon after Marthin discovered Orders.co and realized with app integration and
                Master Menu Management, he could eliminate this issue for good. Now with Orders.co,
                he has all his delivery apps integrated into one device where his staff can make
                availability updates to specific menu items to all connected platforms at once.
                Marthin can also monitor the performance of all his shops from one admin account.`,
    },
  ];

  const sliderSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 2,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 767.9,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Seo
        title={"Customer Stories Pops"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <Testimonials {...testimonialsProps} />
        <Features title="Top Benefits" items={features} />
        <div
          className="bg-desktop pb-0 pb-md-5 mb-0"
          style={{
            backgroundImage: `url(${gradientBg})`,
            backgroundSize: `100% 100%`,
          }}
        >
          <YoutubeVideo
            videoId={"hClOY8sNxkY"}
            image={<StaticImage
              placeholder="none"
              loading="eager"
              alt="youtube-placeholder"
              className="mw-100 w-100 mh-100"
              src="../images/CustomerStoriesPops/Marthin Ken.png"
            />}
          ></YoutubeVideo>
          <Title>
            <b
              className="mt-5 mt-md-0"
              style={{ fontSize: "42.861px", lineHeight: "64px" }}
            >
              All Your Online Orders in One Device
            </b>
          </Title>
          <ImageText {...imageTextProps1} />
          <ImageText {...imageTextProps2} />
          <ImageText {...imageTextProps3} />
          <ImageText {...imageTextProps4} />
        </div>
        <div
          className="d-none d-xl-block"
          style={{ marginBottom: "183px" }}
        ></div>
        <Features
          title={
            <span className="text-center d-block">
              Pops express was struggling maintaining 3 separate tablets with
              limited counterspace.
            </span>
          }
          items={features2}
        />
        <Title>
          <b
            className="text-center text-lg-left d-block"
            style={{ fontSize: "42.861px", lineHeight: "64px" }}
          >
            From Local Diners to Multinational Chains
          </b>
        </Title>
        <div className="container mb-3 mb-lg-5 pb-3 pb-lg-5">
          <Slider {...sliderSettings}>
            <div className="col-12">
              <div className="post-item d-flex flex-column">
                <div className="d-flex justify-content-center">
                  <StaticImage
                    placeholder="blurred"
                    alt="Independent Restaurants"
                    src="../images/CustomerStoriesPops/slider-img-1.png"
                    className="mw-100"
                  />
                </div>

                <Link to="/independent-restaurants/" className="post-title">
                  Independent Restaurants
                </Link>
                <div className="post-description">
                  Orders.co was built from the ground up to support restaurants
                  of varying sizes. Our tools help cut costs.
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="post-item d-flex flex-column">
                <div className="d-flex justify-content-center">
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="Chain Restaurants"
                    src="../images/CustomerStoriesPops/slider-img-2.png"
                    className="mw-100"
                  />
                </div>
                <Link to="/chain-restaurants/" className="post-title">
                  Chain Restaurants
                </Link>
                <div className="post-description">
                  The Orders.co suite was built to facilitate chain restaurants'
                  needs like never before.
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div ref={formRef}>
          <ContactForm></ContactForm>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "customer-stories-pops-artisanal-creamery" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default CustomerStoriesPopsPage;
